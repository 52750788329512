import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import {FileDialogButtonView} from '@ckeditor/ckeditor5-upload/src/index';
import imageIcon from '@ckeditor/ckeditor5-core/theme/icons/image.svg';
import Vue from 'vue'
import {MoodleApi} from "@/modules/core/webservice/moodle-api";

export default class InsertImage extends Plugin {
    constructor( editor: any ) {
        super( editor );

        editor.config.define( 'image', {
            draftItemId: 0
        })
    }

    init() {
        const editor = this.editor
        const schema = editor.model.schema
        const registry = this.registry

        const configDraftItemId = editor.config.get( 'image.draftItemId' )

        // Configure the schema.
        schema.register('img', {
            isObject: true,
            isBlock: true,
            allowWhere: '$block',
            allowAttributes: ['src', 'alt', 'width', 'height', 'class'],
        })

        // Model -> View (element)
        editor.conversion.for('downcast').elementToElement({
            model: 'img',
            view: (modelElement: any, {writer}: any) => {
                let tokenParam = ''

                const token = Vue.prototype.$customStore.getters['login/GET_TOKEN']
                if (token) {
                    tokenParam = '?token=' + token
                }

                const imageUrl = modelElement.getAttribute('src') + tokenParam

                return  this.createImageElement(writer, imageUrl)
            }
        })

        // View -> Model (element)
        editor.conversion.for('upcast')
            // Upcast semantic media.
            .elementToElement({
                view: (element: any) => ['img'].includes(element.name) ? {name: true} : null,
                model: (viewImage: any, {writer}: any) => {
                    const imageUrl = viewImage.getAttribute('src')

                    return writer.createElement('img', {
                        src: imageUrl
                    })
                }
            })
            // Upcast non-semantic media.
            .elementToElement({
                view: {
                    name: 'img',
                },
                model: (viewImage: any, {writer}: any) => {
                    const imageUrl = viewImage.getAttribute('src')

                    return writer.createElement('img', {
                        src: imageUrl
                    })
                }
            })

        const componentCreator = (locale: any) => {
            const view = new FileDialogButtonView(locale)
            const imageTypes = ['png', 'jpg', 'jpeg', 'gif', 'bmp', 'webp', 'tiff']
            const imageTypesRegExp = this.createImageTypeRegExp(imageTypes)

            view.set({
                acceptedType: imageTypes.map((type: any) => `image/${ type }`).join(','),
                allowMultipleFiles: false
            })

            view.buttonView.set({
                label: 'Bild einfügen',
                icon: imageIcon,
                tooltip: true
            })

            view.on('done', (evt: any, files: Iterable<unknown> | ArrayLike<unknown>) => {
                // @ts-ignore
                const imagesToInsert = Array.from(files).filter(file => imageTypesRegExp.test( file.type ))

                if (imagesToInsert.length) {
                    imagesToInsert.forEach(async (image) => {
                        // Upload file to Moodle
                        let itemId = await this.uploadFile(image as File, configDraftItemId)

                        if (itemId === false) {
                            console.log('Error on file upload with file name: ' + (image as File).name)
                        }

                        editor.model.change( (writer: any) => {
                            const userContextId = Vue.prototype.$customStore.getters['login/GET_USER_CONTEXT_ID']

                            let imageUrl = process.env.VUE_APP_MOODLE_API_BASE_URL
                                + '/local/lumaconi/draftfile.php/'
                                + userContextId
                                + '/user/draft/'
                                + configDraftItemId
                                + '/'
                                + (image as File).name

                            const imageElement = writer.createElement('img', {
                                src: imageUrl
                            })

                            // Insert the image in the current selection location.
                            // @ts-ignore
                            editor.model.insertContent(imageElement, editor.model.document.selection)
                        });
                    })
                }
            })

            return view
        }

        editor.ui.componentFactory.add('insertImage', componentCreator)
    }

    createImageElement(writer: any, url: string) {
        const containerElement = writer.createContainerElement('p')

        const image = writer.createContainerElement('img', {
            src: url
        })

        writer.insert(writer.createPositionAt(containerElement, 0), image)

        return containerElement
    }

    createImageTypeRegExp(types: any[] ) {
        // Sanitize the MIME type name which may include: "+", "-" or ".".
        const regExpSafeNames = types.map(type => type.replace('+', '\\+'))
        return new RegExp(`^image\\/(${ regExpSafeNames.join( '|' ) })$`)
    }

    async uploadFile(file: File, draftItemId: number) {
        if(file) {
            let formData = new FormData()
            formData.append('file', file)

            let response = await MoodleApi.upload(formData, draftItemId)

            if(Array.isArray(response) && response.length > 0 && response[0].hasOwnProperty('itemid')) {
                return response[0].itemid
            }

            return false
        }
    }

    /**
     * @inheritDoc
     */
    static get pluginName() {
        return 'InsertImage'
    }
}