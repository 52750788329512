

import {Component, Prop, Vue, Watch} from 'vue-property-decorator'
// @ts-ignore: Vuetify error
import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';
import {CKEditorConfig} from '@/modules/core/ckeditor/ckeditor';
import {MoodleApi} from "@/modules/core/webservice/moodle-api";
import {Page} from "@/modules/page/models/Page";
import {PageResponse} from "@/modules/page/models/response/PageResponse";
import {CKEditorImageConfig} from "@/modules/core/ckeditor/ckeditor-image";

@Component<PageForm>({
    watch: {
        pageReference: {
            handler: function (value: Object, oldValue: Object) {
                this.setInitialValues();
            },
            deep: true,
            immediate: true
        }
    }
})

export default class PageForm extends Vue {

    @Prop({default: null}) pageReference: any

    page: any = null

    nameRules = [
        (v: string) => !!v || "Die Eingabe darf nicht leer sein",
        (v: string) => (v && v.length <= 250) || "Die Eingabe ist zu lang. Es dürfen höchstens 250 Zeichen eingegeben werden",
    ]

    editor = ClassicEditor
    editorConfig = {
        plugins: CKEditorConfig.plugins,
        toolbar: CKEditorConfig.toolbar,
        placeholder: 'hier Inhalt hinzufügen.'
    }

    editorConfigLoaded = false

    valid = true
    panels: number = 3;
    openedPanels = []

    openAllPanels () {
        if (this.panels !== null) {
            this.openedPanels = this.getPanelArr(this.panels);
        }
    }

    closeAllPanels () {
        this.openedPanels = [];
    }

    getPanelArr(panelsInt: number) : any {
        let panelArr: any = [];

        while (panelsInt !== 0) {
            panelArr[panelsInt-1] = panelsInt-1;
            panelsInt--;
        }

        return panelArr;
    }

    setInitialValues() {
        if (this.pageReference !== null) {
            this.page = {
                name: this.pageReference.name,
                description: this.pageReference.description,
                content: this.pageReference.content,
                content_item_id: this.pageReference.content_item_id,
                contentEditorConfig: {
                    plugins: CKEditorImageConfig.plugins,
                    toolbar: CKEditorImageConfig.toolbar,
                    placeholder: 'Inhalt...',
                    image: {
                        draftItemId: this.pageReference.content_item_id
                    }
                }
            }
        } else {
            this.page = {
                name: '',
                description: '',
                content: '',
                content_item_id: 0,
                contentEditorConfig: {
                    plugins: CKEditorImageConfig.plugins,
                    toolbar: CKEditorImageConfig.toolbar,
                    placeholder: 'Inhalt...',
                    image: {
                        draftItemId: 0
                    }
                }
            }
        }
    }

    async created() {
        this.setInitialValues();
        if (this.panels !== null) {
            this.openedPanels = this.getPanelArr(this.panels);
        }

        await this.sleep(2000);
        this.editorConfigLoaded = true
    }

    sleep(ms: number) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    validate() {
        // @ts-ignore: Vuetify error
        if (this.$refs.form.validate()) {
            this.$emit('send', this.page);
        }
    }

    reset() {
        // @ts-ignore: Vuetify error
        if (this.pageReference !== null) {
            this.setInitialValues();
        } else {
            // @ts-ignore: Vuetify error
            this.$refs.form.reset();
            this.page.description = '';
            this.page.content = '';
        }
    }
}

